* {
	font-size: 62.5%;
	margin: 0px;
	padding: 0px;
}

@font-face {
	font-family: "RecifeDisplay-Light";
	src: url("RecifeDisplay-Light.woff2") format("woff2"),
		url("RecifeDisplay-Light.woff") format("woff"),
		url("RecifeDisplay-Light.ttf") format("truetype");
}
@font-face {
	font-family: "MaisonNeue-Light";
	src: url("MaisonNeue-Light.woff2") format("woff2"),
		url("MaisonNeue-Light.woff") format("woff"),
		url("MaisonNeue-Light.ttf") format("truetype");
}

img {
	max-width: 100%;
}

body {
	margin: 0;
}

html,
body {
	box-sizing: border-box;
	margin: 1rem;
	max-height: 100%;
}

h1 {
	font-size: 2rem;
}

p {
	font-size: 1.5rem;
	font-family: "MaisonNeue-Light";
}

a {
	font-family: "MaisonNeue-Light";
}

/* header */

.header-container,
.footer-container {
	/* border: 1px solid black; */
	display: flex;
	height: 10%;
	justify-content: space-between;
	animation: transitionIn 900ms ease-in;
}

.header-container > div {
	display: flex;
	align-items: center;
	flex-basis: 250px;
}

.header-container {
	border-bottom: 1px solid black;
	padding-bottom: 0.5rem;
}

.toggle-container {
	justify-content: flex-end;
}

.toggle,
.model-behaviour,
.next,
.footer {
	cursor: pointer;
	letter-spacing: 0.2rem;
	z-index: 3;
	text-transform: uppercase;
	cursor: pointer;
	transition: 500ms ease-in;
}

a.model-behaviour {
	text-decoration: none;
	color: black;
	z-index: 1;
	font-size: 1.5rem;
}

.toggle:hover,
.model-behaviour:hover,
.next:hover,
.footer:hover {
	color: chocolate;
	letter-spacing: 0.3rem;
	transition: 300ms ease-in;
}

.toggle,
.model-behaviour,
.footer,
.next {
	font-weight: 600;
}

.spin:hover {
	letter-spacing: 0.2rem;
	transition: 600ms ease-in;
	color: black;
}

.spin {
	letter-spacing: 0.4rem;
	z-index: 3;
	cursor: pointer;
	color: white;
	transition: 900ms ease-in;
	animation: spin 800ms ease-in forwards;
	text-transform: uppercase;
	font-weight: 600;
}

@keyframes spin {
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* menu item */

.menu-container {
	position: fixed;
	display: flex;
	justify-content: flex-end;
	z-index: 2;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.navigation-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: chocolate;
	transform: skewX(15deg);
	flex-basis: 100%;
	animation: expand 800ms ease-in forwards;
}

.navigation-close {
	position: fixed;
	display: flex;
	background: chocolate;
	z-index: 2;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	animation: close 1200ms ease-out forwards;
}

@keyframes expand {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes close {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}

.menu-list {
	list-style: none;
	flex-basis: 30px;
}

.list-items-home,
.list-items-worst.behaviour,
.list-items-best.behaviour,
.list-items-bali.behaviour {
	font-size: 1.5rem;
}

a.item {
	text-decoration: none;
	color: black;
}

a.item:hover {
	color: white;
}

a.with-transition {
	transition: 800ms;
	font-size: 5rem;
}

a.with-transition:hover::after {
	content: ".";
}

a.item:visited {
	color: white;
	font-weight: bolder;
}

a.item:visited:hover {
	color: black;
}

/* homepage*/

.home-container {
	display: flex;
	position: relative;
	/* align-items: center;
  justify-content: center; */
	flex-wrap: wrap;
	/* background: cornsilk; */
	margin: 2rem 0rem;
	height: 100%;
	animation: transitionIn 900ms ease-in;
}

.main-image,
.about-container {
	display: flex;
	justify-content: center;
}

.main-image {
	justify-content: flex-start;
	width: 445px;
}

@keyframes transitionIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.half-image:hover {
	transform: scale(2);
	filter: grayscale(100%);
}

.about-container {
	flex: 1 1 420px;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	margin-left: 2rem;
}

.about-inner {
	flex: 1;
}

.her-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 100px;
	width: 810%;
	position: relative;
	/* background-color: teal; */
}

.her-0 {
	padding: 1rem;
	transition: 3500ms ease-in;
	overflow: hidden;
	position: relative;
	flex: 1;
}

.next-button {
	display: flex;
	justify-content: center;
}

.next {
	cursor: pointer;
	z-index: 0;
}

.next.mobile {
	display: none;
}

/* Wb */

.wb-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-color: blanchedalmond; */
	height: 740px;
	margin-top: 2rem;
	animation: transitionIn 900ms ease-in;
}

.wb {
	font-size: 10rem;
	position: relative;
	justify-content: center;
	font-family: "RecifeDisplay-Light";
}

/* .wb-inner {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
} */

.wb.left {
	transform: rotate(-90deg);
	left: 12rem;
}

.wb.right {
	right: 3rem;
	transform: rotate(90deg);
}

.wb-images-container {
	display: flex;
	/* flex-wrap: wrap; */
	overflow: hidden;
	width: 100%;
	height: 800px;
}

.wb-images {
	width: 34%;
	margin-right: 2rem;
}

.wb-main {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex: 1;
}

.wb-images.two {
	margin-right: unset;
}

.wi-container,
.bb-container,
.bb-container-2 {
	display: flex;
	justify-content: space-evenly;
	overflow: hidden;
}

.image.left {
	margin-right: 2rem;
}

.hide-para {
	display: none;
}

/* .image:hover + .hide-para {
  display: flex;
} */

.image:hover,
.bb-images:hover,
.bb-images-right:hover {
	transform: scale(0.8);
	filter: grayscale(100%);
}

.image:hover + .hide-para,
.bb-images-right:hover + .hide-para,
.bb-images:hover + .hide-para {
	display: inline-block;
}

.image.right,
.image.small {
	margin-left: 2rem;
	width: 100%;
}

.image {
	margin-top: 2rem;
	cursor: pointer;
	transition: transform 1000ms ease-out;
}

.image.bottom {
	margin-right: 2rem;
}

.wb-col-1,
.wb-col-2,
.bb-col-1,
.bb-col-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.bb-images {
	flex: 1;
	margin: 2rem 2rem 0 0;
	transition: transform 1000ms ease-out;
	cursor: pointer;
}

.bb-images-right {
	flex: 1;
	margin: 2rem 0rem 0rem 2rem;
	transition: transform 1000ms ease-out;
	cursor: pointer;
}

.contact-container {
	display: flex;
	position: relative;
	height: 674px;
	overflow: hidden;
	margin-top: 2rem;
	animation: transitionIn 900ms ease-in;
}

.contact-info {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	height: 230px;
	padding: 2rem;
}

.contact {
	flex: 1;
	position: relative;
	display: flex;
	align-items: flex-end;
	bottom: 5rem;
}

.contact.button {
	align-items: flex-start;
}

.contact.email,
.contact.button {
	align-items: center;
}

.mail-icon {
	width: 5%;
}

.shanice-email {
	padding-left: 1rem;
}

a.email-button {
	cursor: pointer;
	background: #decfad;
	width: 50%;
	padding: 1.5rem;
	border-radius: 50px;
	box-shadow: -1px 3px 5px #555;
	text-align: center;
	transition: 200ms ease-in;
	color: black;
	text-decoration: none;
	font-size: 1.5rem;
}

.email-button:hover {
	/* transform: scale(0.98); */
	background: #decfad9c;
}

.shanicedr {
	font-size: 5rem;
	font-weight: 600;
	font-family: "RecifeDisplay-Light";
	white-space: pre-line;
	text-transform: lowercase;
}

.shanice-email {
	font-weight: 500;
}

.footer-container {
	align-items: center;
	height: 5vh;
}

a.footer {
	color: black;
	text-decoration: none;
	font-size: 1.5rem;
}

@media (max-width: 768px) {
	.home-container {
		height: unset;
	}
	a.with-transition {
		font-size: 4rem;
	}

	.about-container {
		margin-left: unset;
	}

	.her-container {
		width: 1840%;
		flex-basis: 50px;
	}

	.next.desktop {
		display: none;
	}

	.next.mobile {
		display: flex;
	}

	.wb-container {
		height: 570px;
	}
	.wb-images-container,
	.photo-credits {
		height: unset;
	}

	.wi-container {
		flex-wrap: wrap;
	}

	.image.small,
	.image.right {
		margin-left: 0;
	}

	.bb-images {
		margin-right: 0;
		flex: unset;
	}
	.bb-images-right {
		margin-left: 0;
		flex: unset;
	}

	.bb-container,
	.bb-container-2 {
		flex-wrap: wrap;
	}

	.contact-container {
		height: unset;
		display: flex;
		flex-direction: column;
	}

	.contact-info {
		padding: unset;
		height: 280px;
	}

	.contact-info {
		position: relative;
		padding: 0.5rem;
	}

	.contact {
		bottom: unset;
		flex: 0 1 90px;
	}

	a.email-button {
		width: 50%;
	}

	.contact {
		justify-content: center;
		text-align: center;
	}

	.contact.button {
		align-items: flex-start;
	}

	.hide-para {
		text-align: center;
	}

	.half-image:hover {
		transform: unset;
		filter: grayscale(100%);
	}

	.half-image,
	.main-image {
		height: unset;
	}

	.main-image {
		justify-content: unset;
		width: unset;
		display: unset;
	}

	.wb {
		font-size: 8rem;
	}

	.shanicedr {
		font-size: 3rem;
	}

	.wb-container {
		margin: unset;
	}
}
